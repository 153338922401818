import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const configOptions = {
	apiKey            : 'AIzaSyBI7u_6dSheeuG_1jy1WmpRePuEZGeSqzA',
	authDomain        : 'bonus-panel.firebaseapp.com',
	databaseURL       : 'https://bonus-panel.firebaseio.com',
	projectId         : 'bonus-panel',
	storageBucket     : 'bonus-panel.appspot.com',
	messagingSenderId : '52344969420',
	appId             : '1:52344969420:web:9246856abb3d283f774a69'
};

export const client = 'cortez'

export const app = firebase.initializeApp(configOptions)
export const fb  = firebase.firestore
export const db  = app.firestore()