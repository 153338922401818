<template>
	<div class="home">

		<div v-if="error_message !== ''"> {{ error_message }}</div>

		<div v-else-if="loading" class="loading">Yönlendiriliyorsunuz!...</div>

		<div v-else class="text">
			Betjet kısa url servisi!
		</div>

	</div>
</template>

<script>

	import { db, fb, client } from '@/firebase'

	export default {

		data : () => ( {

			loading       : true,
			get_short_id  : false,
			short_id      : '',
			error_message : '',
			docID         : '',
			pageskin_url  : ''

		} ),

		watch : {

			$route(to) {
				if(to.params.short) {
					this.get_short_id = true
					this.short_id     = to.params.short
					this.get_long_url()
				}
			}

		},

		methods : {
			get_long_url() {


				let $this      = this
					,
					isValidURL = function(string) {
						const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
							'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
							'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
							'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
							'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
							'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
						return !!pattern.test(string);
					}

				const shortConvert = this.short_id.toLowerCase()

				db
				.collection(client + '_links')
				.where('shortUrlId', '==', shortConvert)
				.where('status', '==', 1)
				.get()
				.then(querySnapshot => {

					if(!querySnapshot.empty) {


						const documents = querySnapshot.docs.map(doc => {

							$this.docID = doc.id
							return doc.data()

						}).shift()

						if(isValidURL(documents.longURL)) {

							try {

								let url = new URL(documents.longURL)
								console.log($this.docID)

								db
								.collection(client + '_links')
								.doc($this.docID)
								.update({
									count : fb.FieldValue.increment(1)
								})
								.then(() => {
									document.location.href = documents.longURL
								})


							} catch (err) {

								this.loading       = false
								this.error_message = 'Hatalı url!'

							}

						}


					} else {
						this.error_message = 'Hatalı url!'
					}

				})

			}
		},

		mounted() {

			if(this.$route.params.short) {

				this.get_short_id = true
				this.short_id     = this.$route.params.short
				this.get_long_url()

			} else {

				this.loading = false

				db.collection(client + '_settings')
				.get()
				.then(querySnapshot => {
					querySnapshot.docs.map(doc => {
						if(doc.data().name === 'pageskin_url') this.pageskin_url = doc.data().url
					})

					window.location.href = this.pageskin_url

				})

			}

		}


	}
</script>