import Vue       from 'vue'
import VueRouter from 'vue-router'
import Home      from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
	path      : '/:short?',
	name      : 'Home',
	component : () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
}]

const router = new VueRouter({
	mode : 'history',
	base : process.env.BASE_URL,
	routes
})

export default router
